import React from 'react';
import Button from '../utils/Button';
import TopicCard from './TopicCard';
import EmojiBubble from './EmojiBubble';
import { routes } from '../../pages/routes';

export default function ToggleSection() {
  // state
  const SMEContent = [
    {
      emoji: '🔮',
      title: 'Match with funding',
      paragraph: () => {
        return (
          <div>
            Search is a machine's job.
            <br /> Every day Vula scours the web for new grants and
            opportunities. <br />
            By teaching Vula about your business, you help Vula find funding
            that is a perfect match for you.
          </div>
        );
      },
      visualContent: () => {
        return (
          <video
            key={'Match with funding'}
            controls={false}
            autoPlay
            loop={true}
            muted
            preload="metadata"
            poster={
              'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/MatchWithGrants.png'
            }
            playsInline={true}
            className="w-full h-full object-cover rounded-3xl "
          >
            {/* <!-- Fallback content for non-supported browsers --> */}
            <source
              src={
                'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/MatchWithGrants.webm'
              }
              type="video/webm"
            />
            <source
              src={
                'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/MatchWithGrants.mp4'
              }
              type="video/mp4"
            />
            {/* <!-- Provide a download link for users who can't play the video --> */}
            {/* Your browser does not support the video tag. You can{' '}
          <a href="app_intro.mp4">download the video here</a>. */}
          </video>
        );
      },
    },
    {
      emoji: '🧈',
      title: 'Apply with ease',
      paragraph: () => {
        return (
          <div>
            Vula drafts answers on your application forms. <br />
            You can teach Vula about your business by showing it your website,
            uploading a memo or any existing company docs. No more starting from
            a blank page. You’ve already done the work, Vula just helps you put
            it into words.
          </div>
        );
      },
      visualContent: () => {
        return (
          <video
            key={'Apply with ease with Vula prewriting'}
            controls={false}
            autoPlay
            loop={true}
            muted
            preload="metadata"
            poster={
              'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/AskVulaGrants.png'
            }
            playsInline={true}
            className="w-full h-full object-cover rounded-3xl "
          >
            {/* <!-- Fallback content for non-supported browsers --> */}
            <source
              src={
                'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/AskVulaGrants.webm'
              }
              type="video/webm"
            />
            <source
              src={
                'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/AskVulaGrants.mp4'
              }
              type="video/mp4"
            />
            {/* <!-- Provide a download link for users who can't play the video --> */}
            {/* Your browser does not support the video tag. You can{' '}
          <a href="app_intro.mp4">download the video here</a>. */}
          </video>
        );
      },
    },
    {
      emoji: '🤬',
      title: 'Never repeat yourself',
      paragraph: () => {
        return (
          <div>
            90% of applications ask the same questions.
            <br /> Vula serves as your company knowledge-base and auto fills
            answers you’ve already given. You can always polish and update
            responses as your company grows and changes, helping you improve
            your chances of securing funding.
          </div>
        );
      },
      visualContent: () => {
        return (
          <video
            key={'Match with grants'}
            controls={false}
            autoPlay
            loop={true}
            muted
            preload="metadata"
            poster={
              'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/generate-answer.png'
            }
            playsInline={true}
            className="w-full h-full object-cover rounded-3xl "
          >
            {/* <!-- Fallback content for non-supported browsers --> */}
            <source
              src={
                'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/generate-answer.webm'
              }
              type="video/webm"
            />
            <source
              src={
                'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/generate-answer.mp4'
              }
              type="video/mp4"
            />
            {/* <!-- Provide a download link for users who can't play the video --> */}
            {/* Your browser does not support the video tag. You can{' '}
          <a href="app_intro.mp4">download the video here</a>. */}
          </video>
        );
      },
    },
  ];

  const renderContent = (content: {
    emoji: string;
    title: string;
    paragraph: () => JSX.Element;
    visualContent?: () => JSX.Element;
  }) => {
    return (
      <TopicCard key={content.title}>
        <EmojiBubble emoji={content.emoji} />

        <div className="text-3xl sm:text-4xl font-bold py-8">
          {content.title}
        </div>
        <div className="text-lg w-full sm:w-5/6">{content.paragraph()}</div>
        <div className="w-full mt-4">
          {content.visualContent && content.visualContent()}
        </div>
      </TopicCard>
    );
  };

  const renderSMEContent = () => {
    return (
      <div className="pt-40  text-left fade-in">
        <div className="px-8  max-w-[800px] m-auto sm:px-20">
          <div className="text-5xl ">
            For{' '}
            <span className="font-bold bg-gradient-to-r bg-clip-text text-transparent to-[#DF988D] via-[#E3AB58] from-[#BCC0CF]">
              SMEs
            </span>
          </div>
          <div className="text-2xl mt-8 w-full">
            Vula helps you access a range of funding options in one place and
            keeps you alerted when new opportunities arise, so that you can
            spend your time and effort on growing your business instead of
            chasing after funders.
          </div>
          <div className="py-8">
            <a href={routes.public.signup}>
              <Button text="Start now" />
            </a>
          </div>
        </div>

        {SMEContent.map(content => {
          return renderContent(content);
        })}

        <div className=" max-w-[500px] m-auto ">
          <div className="py-12 px-4 text-center flex flex-col justify-center items-center">
            <div className="text-lg sm:text-2xl mt-8 ">
              How much funding are you missing <b>right now?</b>
              <br />
              <br />
              Get your free account today.
            </div>
            <div className="pt-8">
              <a href={routes.public.signup}>
                <Button text="Find me funding" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFunderContent = () => {
    return (
      <div className="pt-40 text-left fade-in">
        <div className=" text-white py-10 font-bold text-shadow bg-center bg-[url(https://vula-assets.s3.eu-west-2.amazonaws.com/hopeful.webp)]">
          <div className=" max-w-[500px] m-auto">
            <TopicCard>
              <div className="py-12 text-center flex flex-col justify-center items-center">
                <div className="text-5xl">Are you an</div>
                <div className=" text-5xl mt-2 font-bold bg-gradient-to-r bg-clip-text text-transparent to-[#DF988D] via-[#E3AB58] from-[#BCC0CF]">
                  SME Funder?
                </div>
                <div className="text-xl mt-8 ">
                  Learn how we can save 80% of your processing time.
                </div>
                <div className="pt-8">
                  <a href={routes.public.os}>
                    <Button text="Learn more" />
                  </a>
                </div>
              </div>
            </TopicCard>
          </div>
        </div>
      </div>
    );
  };

  const renderWhenFounder = () => {
    return (
      <div>
        {renderSMEContent()}
        {renderFunderContent()}
      </div>
    );
  };

  return <div className="pt-20 ">{renderWhenFounder()}</div>;
}
