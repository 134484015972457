import React, { useEffect, useRef, useState } from 'react';
import EmojiBubble from './EmojiBubble';
import TopicCard from './TopicCard';

export default function CommonTheme() {
  const renderApplicationForm = (name: string, from: string, to: string) => {
    return (
      <div className="">
        <div className="bg-stone-300 text-stone-800 rounded-lg w-[175px] sm:w-[210px] m-auto sm:h-[300px] p-1">
          <div
            className={` flex justify-start text-stone-100 px-2 mb-4 items-center h-8 bg-stone-300 rounded text-sm text-left w-full bg-gradient-to-r ${from} ${to} `}
          >
            {name}
          </div>
          <div className="h-8"></div>
          <div className=" w-full text-stone-100 text-xs text-left text-stone-900 flex flex-row justify-center items-center ">
            <div className="border border-1 border-solid border-stone-700 w-1/3"></div>
            <div className="px-3 text-[8px]">or</div>
            <div className="border border-1 border-solid border-stone-700 w-1/3"></div>
          </div>
          <div className="">
            <div className="">
              {['Name', 'Email', 'URL', 'Upload pitch deck'].map(item => (
                //  This is a form
                <div className=" p-2 text-[8px] text-left w-full">
                  <div className="w-1/2">{item}</div>
                  <div className="w-full bg-stone-400 rounded p-2"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="-mt-[100px] w-full   h-[100px]         bg-gradient-to-b from-transparent to-stone-800"></div>
        <div className="mt-[0px]"></div>
      </div>
    );
  };

  const forms = [
    renderApplicationForm('Grant X', 'from-orange-500', 'to-red-500'),
    renderApplicationForm('Loan Y', 'from-blue-500', 'to-green-500'),
    renderApplicationForm('Accelerator Z', 'from-red-500', 'to-purple-500'),
    renderApplicationForm('Programme W', 'from-teal-500', 'to-pink-500'),
  ];
  const delay = 5000;

  const [index, setIndex] = useState(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex(prevIndex => (prevIndex + 1) % forms.length);
    }, delay);

    return () => {
      resetTimeout();
    };
  }, [index]);

  const handleAnimationEnd = () => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex(prevIndex => (prevIndex + 1) % forms.length);
    }, 2000); // Dwell time in milliseconds
  };

  return (
    <TopicCard>
      <div className="flex flex-col sm:flex-row justify-between items-center">
        <div className="sm:w-1/2 sm:pr-8">
          <EmojiBubble emoji="🤝" />
          <div className="text-3xl pt-8 ">
            Imagine applying for funds in <b>one click</b>
          </div>
          <div className="text-xl py-4">
            SMEs and Funders will close the funding gap together, but both need
            some AI help.
            <br />
            <br />
            Vula is that help.
          </div>
        </div>
        <div className="sm:w-1/2 flex justify-center items-center relative overflow-hidden">
          <div className="w-[300px] sm:w-[600px] flex justify-center overfow-hidden">
            <div
              className="flex flex-row justify-center overflow-hidden sm:w-[500px] "
              onAnimationEnd={handleAnimationEnd}
            >
              {forms.map((form, formIndex) => (
                <div
                  className={` w-full px-2 carousel-wrapper`}
                  style={{
                    animationDelay:
                      (10 * (1 + formIndex)) / forms.length + 'ms',
                  }}
                >
                  <div key={formIndex} className="slide">
                    {form}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="absolute top-10 scale-[0.9] my-2 px-3 flex justify-center items-center h-8 bg-stone-100 rounded-full text-stone-900 border border-1 border-solid border-stone-200 drop-shadow-xl text-xs text-left w-fit m-auto animate-border duration-500 bg-gradient-to-br to-[#ffffff00] via-[#ffffff00] from-[#AABBDD] bg-[length:400%_400%] ">
            <img className="h-3 pr-2" src="/logo.png" />
            <p>apply with</p> <b className="px-1">vula</b>
          </div>
        </div>
      </div>
    </TopicCard>
  );
}
