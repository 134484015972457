import React from 'react';

import VulaLogo from '../utils/VulaLogo';
import LoginButton from '../../interfaces/auth0/LoginButton';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../pages/routes';

export default function Navbar() {
  const navigate = useNavigate();
  const [show, setShow] = React.useState(
    // if we're on the landing page, don't show the navbar
    window.location.pathname === '/' ? false : true,
  );

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return (
    <header
      className={
        'max-w-full m-auto  top-0 z-50  opacity-0 transition-opacity delay-[2000ms] duration-500 ease-in-out' +
        (show ? ' opacity-100' : ' ')
      }
    >
      <div className="flex justify-between items-center p-2 ">
        <div className="pl-2 sm:pl-4 w-1/3">
          <VulaLogo />
        </div>
        <div className="hidden sm:flex flex-row justify-center items-center text-stone-500 text-sm w-1/3">
          <button
            className="mx-1 p-3 hover:bg-stone-100 rounded-lg"
            onClick={() => {
              const section = document.getElementById('SMEContent');
              if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
              }
              return;
            }}
          >
            SMEs & Startups
          </button>
          <button
            className="mx-1 p-3 hover:bg-stone-100 rounded-lg"
            onClick={() => {
              return navigate(routes.public.os);
            }}
          >
            SME funders
          </button>
          <button
            className="mx-1 p-3 hover:bg-stone-100 rounded-lg"
            onClick={() => {
              return navigate(routes.public.submitGrant);
            }}
          >
            Grant providers
          </button>
        </div>
        {/* <div className='sm:text-lg'><b>vula</b> <span className = 'italic'>(verb): </span>  isiXhosa for "open" </div> */}
        <div className="pr-1 flex justify-end flex-row items-center w-1/3">
          <LoginButton type="login" buttonType="plain" />
          <div className="hidden sm:block">
            <LoginButton type="signup" />
          </div>
        </div>
      </div>
    </header>
  );
}
