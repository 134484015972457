export const convertToReadableNumber = (number: number) => {
  const numberOfDigits = number.toString().length;

  if (numberOfDigits < 2) {
    // ie 20
    return number;
  } else if (numberOfDigits < 7) {
    // ie 200 -> 0.2k
    return number / 1000 + 'k';
  } else {
    // ie 200000 -> 0.2M
    return number / 1000000 + 'M';
  }
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const modifyToAbsoluteUrl = (url: string) => {
  let company_url = '';
  if (checkIsValidUrl(url) === 'full') {
    company_url = url;
  } else if (checkIsValidUrl(url) === 'partial') {
    company_url = create_url_from_partial(url);
  }
  return company_url;
};

export const checkIsValidUrl = (url: string) => {
  // check it has a point
  if (url.includes('.')) {
    // check construct
    if (isValidUrl(url)) {
      return 'full';
    } else {
      if (isValidUrl(create_url_from_partial(url))) {
        return 'partial';
      }
    }
  }

  return false;
};

const isValidUrl = (url: string) => {
  try {
    // check if fits url regex
    const urlPattern = new RegExp(
      /^(?:http(s)?:\/\/)?[a-zA-Z0-9.-]+(?:\.[a-zA-Z]{2,})+(?:[/\w-]*)*(?:\?[a-zA-Z0-9]+=[a-zA-Z0-9-]+)*(?:#[a-zA-Z]+)?$/,
    );

    return urlPattern.test(url);
  } catch (e) {
    return false;
  }
};

export const create_url_from_partial = (partial_url: string) => {
  return 'https://' + partial_url;
};

export const replaceHTMLEncoding = (str: string): string => {
  return str
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'");
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const formattedDate = date.toLocaleDateString('en-UK', options);

  // Split the date string into parts
  const [day, month, year] = formattedDate.split(' ');

  // Add the appropriate ordinal suffix to the day
  let suffix;
  if (day === '1' || day === '21' || day === '31') {
    suffix = 'st';
  } else if (day === '2' || day === '22') {
    suffix = 'nd';
  } else if (day === '3' || day === '23') {
    suffix = 'rd';
  } else {
    suffix = 'th';
  }

  // Return the formatted date string
  return `${day}${suffix} ${month} ${year}`;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export function timeoutWrapper<T>(
  originalFunction: () => Promise<T>,
  timeout: number,
  defaultValue: T,
) {
  return Promise.race([
    originalFunction(),
    new Promise(resolve => setTimeout(resolve, timeout, defaultValue)),
  ]);
}

// create a random number between 0 and a given max
export const getRandomNumber = (max: number) => {
  return Math.floor(Math.random() * max);
};
