import React from 'react';
import { ApplicationContextPublic } from '../../../contexts/ApplicationContextPublic';
import CreateACompanyViaPublicForms from './CreateACompanyViaPublicForms';

import ReadingBanner from './ReadingBanner';
import VulaLogo from '../../utils/VulaLogo';
import { capitalizeFirstLetter } from '../../../methods/UIHelpers';
import ApplyWithVulaButton from './ApplyWithVulaButton';
import { track } from '../../../interfaces/mixpanel';

export default function PublicFormHeader() {
  const {
    applicationStepName,
    company,
    resetForm,
    partner,
    formDefinition,
    resubmitApplicationWithUrl,
  } = React.useContext(ApplicationContextPublic);

  if (!formDefinition || 'error' in formDefinition) {
    return null;
  }

  const getBanner = (applicationStepName: string) => {
    switch (applicationStepName) {
      case 'readingWebsite':
        return (
          <ReadingBanner
            messages={[
              'Finding your website',
              'Reading your website',
              'Training Vula to help you apply',
            ]}
          />
        );
      case 'readingDocuments':
        track('Public Form - reading documents');
        return (
          <ReadingBanner
            messages={[
              'Storing your doc(s) securely',
              'Extracting text from docs',
              'Reading your docs',
              'Creating a company profile',
              'Training Vula to help your company apply',
              'Pre-filling the application form',
              'Almost done!',
            ]}
          />
        );
      // case 'readingBoth':
      //   return (
      //     <>
      //       <h2 className="text-xl font-bold pl-2 ">Apply with AI</h2>
      //       <div className="text-sm pl-2 ">
      //         Add your website and / or add your business plan or pitch deck and we
      //         will prefill the form with the information from your documents.
      //       </div>
      //     </>
      //   );
      case 'readingComplete':
        track('Public Form - reading complete');
        return (
          <>
            <div className="sm:w-full">
              <img
                src={
                  company && company.logo
                    ? company.logo
                    : formDefinition?.partner_logo
                }
                alt="Company Logo"
                className="max-h-[100px] m-auto animate fade-in drop-shadow rounded-xl overflow-hidden"
                onError={
                  ((e: any) => {
                    e.currentTarget.style.display = 'none';
                  }) as any
                }
              />
            </div>
            <div className="text-lg w-full justify-center flex items-center animate fade-in">
              Hello
              {company?.company_full_name
                ? ` ${company.company_full_name}`
                : ''}
            </div>
          </>
        );
      case 'completed':
        track('Public Form - sme completed application');
        return (
          <>
            <div className="sm:w-full min-h-[300px] flex sm:flex-row flex-col items-center ">
              {company?.logo ? (
                <img
                  src={company.logo}
                  alt="Company Logo"
                  onError={e => {
                    e.currentTarget.style.display = 'none';
                  }}
                  className="max-h-[100px] m-auto animate fade-in drop-shadow rounded-xl overflow-hidden"
                />
              ) : null}
              {formDefinition?.partner_logo ? (
                <img
                  src={formDefinition?.partner_logo}
                  alt="Company Logo"
                  className="max-h-[100px] m-auto animate fade-in drop-shadow rounded-xl overflow-hidden"
                />
              ) : null}
            </div>
            <div className="text-center text-lg  flex justify-center items-center animate fade-in min-h-[500px]">
              Thank you for applying
              {company?.company_full_name
                ? ` ${company.company_full_name}`
                : ''}
              .
              <br />
              {partner.partner_name
                ? capitalizeFirstLetter(partner.partner_name)
                : 'We'}{' '}
              will be in touch soon.
            </div>
            <button
              className="text-center w-full"
              onClick={async () => {
                await resetForm();
              }}
            >
              <div className="underline text-xs hover:text-stone-400 text-stone-300 pt-3 transition-all duration-300">
                Start a new application
              </div>
            </button>
          </>
        );
      case 'error-company-exists':
        return (
          <div className=" w-full bg-white ">
            <div className="flex justify-center items-center h-20 w-20 ">
              <VulaLogo />
            </div>

            <p className="text-2xl font-bold pl-4 pb-4">
              Company already exists!
            </p>
            <p className="text-sm pl-4">
              Please use a different url, or login to your Vula account.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center w-full ">
              <ApplyWithVulaButton
                redirectUrl={formDefinition?.apply_with_vula_url}
                light={false}
                openInNewTab={true}
              />
              <div className="w-full m-auto">
                <button
                  className=" w-fit m-auto rounded-full border border-1 border-solid border-stone-100 hover:border-stone-200 hover:bg-stone-100  transition-all duration-300 text-stone-300 hover:text-stone-600 px-12 py-4 flex justify-center items-center"
                  onClick={async () => {
                    await resetForm();
                  }}
                >
                  <div className="underline text-xs ">Try a different url</div>
                </button>
              </div>
            </div>
          </div>
        );

      case 'error':
        track('Public Form - error');
        return (
          <div className="w-full bg-white">
            <div className="flex justify-center items-center h-20 w-20 ">
              <VulaLogo />
            </div>
            <p className="text-2xl font-bold p-4">Something went wrong!</p>
            <p className="text-sm pl-4">
              Please use a different url or fill in the form manually.
            </p>
            <div className="px-4 pt-4 flex flex-col sm:flex-row items-start justify-start  ">
              <button
                className=" w-fit rounded-full border border-1 border-solid border-stone-100 hover:border-stone-200 bg-stone-800 hover:bg-stone-700  transition-all duration-300 text-stone-200 hover:text-stone-300 px-12 py-4 flex justify-center items-center"
                onClick={async () => {
                  await resetForm();
                }}
              >
                {/* ensure it is on one line */}
                <div className="underline text-xs whitespace-nowrap">
                  Try a different url or start again
                </div>
              </button>
              <div className="pl-8">
                <button
                  className=" w-fit  rounded-full border border-1 border-solid border-stone-100 hover:border-stone-200 bg-stone-200 hover:bg-stone-100  transition-all duration-300 text-stone-800 hover:text-stone-700 px-12 py-4 flex justify-center items-center"
                  onClick={async () => {
                    await resubmitApplicationWithUrl();
                  }}
                >
                  <div className="underline text-xs ">Retry</div>
                </button>
              </div>
            </div>
          </div>
        );
      case 'hide':
        return null;
      default:
        return <CreateACompanyViaPublicForms />;
    }
  };

  if (applicationStepName === 'hide') {
    return null;
  }

  return (
    <div
      key={applicationStepName}
      className={
        'transition-all duration-500 ease-in-out ' +
        (applicationStepName === ''
          ? ''
          : ' p-2 sm:p-2 shadow rounded-xl overflow-hidden bg-white w-full text-start ')
      }
    >
      {getBanner(applicationStepName)}
    </div>
  );
}
