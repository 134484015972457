import React from 'react';
import EmojiBubble from './EmojiBubble';

export default function MeetVula() {
  return (
    <div className="w-[99%] max-w-[800px] m-auto mt-4 rounded-[60px] rounded-b-[200px] sm:rounded-b-[42px] sm:rounded-r-[300px]  text-white  font-bold text-shadow bg-center bg-[url(https://vula-assets.s3.eu-west-2.amazonaws.com/hopeful.webp)]">
      <div className="p-8  text-left flex sm:flex-row flex-col justify-start items-center">
        <div className="sm:w-1/2">
          <EmojiBubble emoji="👋" />
          <div className="text-5xl pt-8 ">
            <span className="font-thin">Meet</span>{' '}
            <b className="font-extrabold">Vula</b>
          </div>
          <div className="py-8 ">
            Vula is your AI funding agent - the next-generation of SME funding
            infrastructure.
            <br />
            <br />
            Vula helps <b>funders</b> digitise SME onboarding
            and vetting, growing dealflow, increasing data quality,
            and optimising the back office. <br />
            <br />
            Vula super-powers SMEs to easily find and apply to funding. That
            means less time chasing funders and more time building business
          </div>
        </div>
        <div className="sm:w-1/2 flex justify-center items-center">
          <img
            src="/assets/raw.gif"
            alt="Vula Logo"
            className="w-[90%] rounded-full sm:w-full"
          />
        </div>
      </div>
    </div>
  );
}
