import React from 'react';
import { track } from '../interfaces/mixpanel';
import { useAuth0 } from '@auth0/auth0-react';

import { routes } from './routes';
import { Navigate } from 'react-router-dom';

import Loading from './Loading';
import LandingNavbar from '../components/Landing/LandingNavbar';
import LandingHeader from '../components/Landing/LandingHeader';
import CommonTheme from '../components/Landing/CommonTheme';
import MeetVula from '../components/Landing/MeetVula';
import ToggleSection from '../components/Landing/ToggleSection';
import JunkDrawer from '../components/Landing/JunkDrawer';

export default function LandingPage() {
  const { isAuthenticated, isLoading, error } = useAuth0();
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      // track landing page loaded after delay to only call it when user stays
      track('Landing Page Loaded', {
        referrer: document.referrer,
      });
      setShow(true);
    }, 3000);
  }, []);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: routes.private.home,
        }}
      />
    );
  }

  return (
    <div
      className="h-full min-h-[100vh] m-auto flex items-center flex-col bg-white "
      style={{
        // Set background image to the dot.svg file
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><circle cx="10" cy="10" r="0.5" fill="%23b0b0b0" /></svg>')`,
        /* Set background repeat to repeat both horizontally and vertically */
        backgroundRepeat: 'repeat',
      }}
    >
      <div className="max-w-[2600px] w-full h-full">
        <LandingNavbar />
        <LandingHeader />
        {
          <div
            id="SMEContent"
            className={
              'opacity-0 transition-opacity  duration-500 ease-in-out' +
              (show ? ' opacity-100' : ' ')
            }
          >
            <CommonTheme />
            <MeetVula />
            <ToggleSection />
            <div className="text-4xl max-w-[500px] m-auto py-40 pb-20">
              We are closing the SME funding gap to raise Africa.
              <br />
              <br />
              Are you with us?
            </div>
            <JunkDrawer />
          </div>
        }
      </div>
    </div>
  );
}
