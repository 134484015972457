import React from 'react';
import Button from '../utils/Button';

import { routes } from '../../pages/routes';

export default function LandingHeader() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return (
    <div className=" flex justify-center text-left">
      <div className="min-h-[70vh] h-auto max-w-[1400px] w-full flex justify-center">
        <div className=" flex flex-col items-center justify-center text-center w-full pb-12">
          <div className="flex flex-col items-center justify-center text-center">
            <h1
              className={
                'fade-in m-auto font-extrabold text-5xl sm:text-6xl lg:text-8xl mt-32 '
              }
              style={{ fontWeight: 1900 }}
            >
              <div
                className={
                  'opacity-0 transition-opacity duration-500 ease-in-out' +
                  (show ? ' opacity-100' : ' ')
                }
              >
                African
              </div>
              <div
                className={
                  'opacity-0 transition-opacity delay-300 duration-500 ease-in-out py-6' +
                  (show ? ' opacity-100' : ' ')
                }
              >
                funding
              </div>
              <div
                className={
                  'animate-text  bg-gradient-to-r bg-clip-text text-transparent to-[#DF988D] via-[#E3AB58] from-[#BCC0CF]  px-4 opacity-0 transition-opacity delay-700 duration-500 ease-in-out' +
                  (show ? ' opacity-100' : ' ')
                }
              >
                fixed.
              </div>
            </h1>

            <div
              className={
                'opacity-0 transition-opacity delay-[1.2s] duration-500 ease-in-out  m-auto text-lg sm:text-xl lg:text-2xl py-6 max-w-[500px] pt-12' +
                (show ? ' opacity-100' : ' ')
              }
            >
              Africa's AI Funding Assistant
            </div>

            <a
              href={
                routes.public.signup +
                // ensure all query params are passed
                (window.location.search || '')
              }
              className={
                'mt-12 opacity-0 transition-opacity delay-[1500ms] duration-500 ease-in-out' +
                (show ? ' opacity-100' : ' ')
              }
            >
              <Button text="Start now" hoveroverText="It's free!" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
